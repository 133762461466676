import React from "react";
import { useDark } from "utils/hooks";

export default function ToggleSwitch() {
  const [, toggleDark] = useDark();
  return (
    <button
      aria-label="toggle dark mode"
      className={`absolute flex items-center w-10 h-5 transition duration-300 dark:bg-gray-100 bg-yellow-50 rounded-full shadow top-6 right-4 focus:outline-none z-50`}
      onClick={toggleDark}
    >
      <div
        id="switch-toggle"
        className={`relative w-6 h-6 p-1 text-white transition duration-250 transform dark:bg-gray-700 dark:translate-x-full bg-yellow-500 -translate-x-2 rounded-full`}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          {/* MOON */}
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"
            className="hidden dark:block"
          />
          {/* SUN */}
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z"
            className="block dark:hidden"
          />
        </svg>
      </div>
    </button>
  );
}
