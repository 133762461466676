import React from "react";

export default function Hamburger({ open }) {
  return (
    <>
      <div id="hamburger" className={`menu_icon ${open ? "clicked" : ""}`}>
        <span className="one"></span>
        <span className="two"></span>
        <span className="three"></span>
      </div>
    </>
  );
}

//  <a
//         href="#main-menu"
//         id="main-menu-toggle"
//         class="menu-toggle"
//         aria-label="Open main menu"
//       >
//         <span class="sr-only">Open main menu</span>
//         <span id="hamburger" aria-hidden="true"></span>
//       </a>

//       <h1 class="logo">hamburgers</h1>

//       <nav id="main-menu" class="main-menu" aria-label="Main menu">
//         <a
//           href="#main-menu-toggle"
//           id="main-menu-close"
//           class="menu-close"
//           aria-label="Close main menu"
//         >
//           <span class="sr-only">Close main menu</span>
//           <span class="fa fa-close" aria-hidden="true"></span>
//         </a>
//         <ul>
//           <li>
//             <a href="#">Products</a>
//           </li>
//           <li>
//             <a href="#">About</a>
//           </li>
//           <li>
//             <a href="#">Contact</a>
//           </li>
//         </ul>
//       </nav>
//       <a
//         href="#main-menu-toggle"
//         class="backdrop"
//         tabindex="-1"
//         aria-hidden="true"
//         hidden
//       ></a>
