import Link from "next/link";

export default function CustomLink({
  href,
  text,
  children,
  containerClassName = "",
  anchorClassName,
  ...props
}) {
  return (
    <div className={containerClassName || "link-container"}>
      <Link href={href} {...props}>
        <a className={anchorClassName || "custom-underline"}>
          {children || text}
        </a>
      </Link>
    </div>
  );
}
