const env =
  process.env.NODE_ENV ||
  process.env.NEXT_PUBLIC_ENV ||
  process.env.NEXT_PUBLIC_VERCEL_ENV;

export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_ID;

/**
 * @function pageview Tracks page view on GA
 * @description https://developers.google.com/analytics/devguides/collection/gtagjs/pages
 * @param {string} url
 */
export const pageview = (url) => {
  env === "production" &&
    window.gtag &&
    window.gtag("config", GA_TRACKING_ID, {
      page_path: url,
    });
};

/**
 * @function event Logs event to GA
 * @description https://developers.google.com/analytics/devguides/collection/gtagjs/events
 * @param {object} props { action, category, label, value }
 */
export const event = ({
  action,
  category,
  label,
  value,
  nonInteraction = false,
}) => {
  env === "production" &&
    window.gtag &&
    window.gtag("event", action, {
      event_category: category,
      event_label: label,
      value,
      non_interaction: nonInteraction,
    });
};
