import React, { Children } from "react";
import { useRouter } from "next/router";
import Link from "next/link";

export default function Nav({ children, activeClassName, ...props }) {
  const { asPath } = useRouter();
  const child = Children.only(children);
  const childClassName = child.props.className || "";
  const className =
    asPath === props.href || asPath === props.as
      ? `${childClassName} ${activeClassName}`.trim()
      : childClassName;

  return (
    <div className="link-container">
      <Link {...props} className={`px-3 py-2 hover:text-gray-400`}>
        {React.cloneElement(child, {
          className: `${className || null} custom-underline`,
        })}
      </Link>
    </div>
  );
}
