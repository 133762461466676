import React from "react";
import { useRouter } from "next/router";
import { DefaultSeo } from "next-seo";
import Header from "components/header";
import Footer from "components/footer";
import { pageview, event } from "utils/gtag";
import "tailwindcss/tailwind.css";
import "styles/globals.css";
import "styles/glitch.css";
import "styles/hamburger.css";

export function reportWebVitals({ id, name, label, value }) {
  // const body = JSON.stringify({ id, name, label, value });
  // const url = "https://example.com/analytics";
  // Use "navigator.sendBeacon()" if available, falling back to "fetch()".
  // if (navigator.sendBeacon) {
  //   navigator.sendBeacon(url, body);
  // } else {
  //   fetch(url, { body, method: "POST", keepalive: true });
  // }
  // If using Google Analytics
  event({
    action: name,
    category: label === "web-vital" ? "Web Vitals" : "Next.js custom metric",
    event_label: id, // id unique to current page load
    non_interaction: true,
  });
}

function MyApp({ Component, pageProps, err }) {
  const router = useRouter();
  React.useEffect(() => {
    const handleRouteChange = (url) => {
      pageview(url);
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);
  return (
    <>
      <DefaultSeo
        titleTemplate={`${process.env.NEXT_PUBLIC_SITE_NAME} | %s`}
        defaultTitle={process.env.NEXT_PUBLIC_SITE_NAME}
        description={process.env.NEXT_PUBLIC_SITE_DESCRIPTION}
        openGraph={{
          type: "website",
          locale: "en_GB",
          url:
            process.env.NEXT_PUBLIC_URL || process.env.NEXT_PUBLIC_VERCEL_URL,
          site_name: process.env.NEXT_PUBLIC_SITE_NAME,
          description: process.env.NEXT_PUBLIC_SITE_DESCRIPTION,
          profile: {
            firstName: process.env.NEXT_PUBLIC_OG_PROFILE_FIRST_NAME,
            lastName: process.env.NEXT_PUBLIC_OG_PROFILE_LAST_NAME,
          },
        }}
        twitter={{
          handle: process.env.NEXT_PUBLIC_TWITTER_HANDLE,
          cardType: "summary_large_image",
        }}
        facebook={{
          appId: process.env.NEXT_PUBLIC_FACEBOOK_APP_ID,
        }}
        additionalLinkTags={[
          {
            rel: "icon",
            href: "./favicon.ico",
          },
          {
            rel: "apple-touch-icon",
            sizes: "180x180",
            href: "./apple-touch-icon.png",
          },
          {
            rel: "icon",
            type: "image/png",
            sizes: "32x32",
            href: "./favicon-32x32.png",
          },
          {
            rel: "icon",
            type: "image/png",
            sizes: "16x16",
            href: "./favicon-16x16.png",
          },
          {
            rel: "manifest",
            href: "./site.webmanifest",
          },
          {
            rel: "mask-icon",
            href: "./safari-pinned-tab.svg",
            color: "#5bbad5",
          },
        ]}
        additionalMetaTags={[
          {
            httpEquiv: "X-UA-Compatible",
            content: "IE=edge",
          },
          {
            name: "viewport",
            content: "width=device-width,initial-scale=1",
          },
          {
            name: "application-name",
            content: process.env.NEXT_PUBLIC_SITE_NAME,
          },
          {
            name: "apple-mobile-web-app-capable",
            content: "yes",
          },
          {
            name: "apple-mobile-web-app-status-bar-style",
            content: "default",
          },
          {
            name: "apple-mobile-web-app-title",
            content: process.env.NEXT_PUBLIC_SITE_NAME,
          },
          {
            name: "mobile-web-app-capable",
            content: "yes",
          },
          {
            name: "format-detection",
            content: "telephone=no",
          },
          {
            name: "msapplication-TileColor",
            content: "#da532c",
          },
          {
            name: "theme-color",
            content: "#4338ca",
          },
          {
            httpEquiv: "theme-color",
            content: "#4338ca",
          },
          {
            name: "keywords",
            content:
              "Jonathan Carlson, programmer, developer, web developer, web development, web design, design, react, squarespace, user experience",
          },
        ]}
      />
      <div className="min-h-screen layout-grid">
        <Header />
        <Component {...pageProps} err={err} />
        <Footer />
      </div>
    </>
  );
}

export default MyApp;
