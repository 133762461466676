import React from "react";

export function useDark() {
  const getInitialTheme = () => {
    if (typeof window !== "undefined" && window.localStorage) {
      const storedPrefs = localStorage.getItem("theme");
      if (typeof storedPrefs === "string") {
        return storedPrefs === "dark";
      }
      const userMedia = window.matchMedia("(prefers-color-scheme: dark)");
      if (userMedia.matches) {
        return true;
      }
    }
    return false;
  };
  const toggleTheme = (dark) => {
    if (typeof window !== "undefined" && window.localStorage) {
      const root = window.document.documentElement;
      const theme = dark ? "dark" : "light";
      root.classList.remove(dark ? "light" : "dark");
      root.classList.add(theme);
      localStorage.setItem("theme", theme);
    }
  };
  const [dark, setDark] = React.useState(getInitialTheme());
  React.useEffect(() => {
    toggleTheme(dark);
  }, [dark]);
  const toggleDark = () => setDark((state) => !state);
  return [dark, toggleDark];
}
