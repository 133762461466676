import Link from "components/link";

export default function Footer() {
  return (
    <footer role="contentinfo" id="footer" className="p-6 xs:p-8">
      <div className="flex justify-between sm:px-4 lg:px-12 2xl:px-24">
        <ul className="flex flex-col space-y-2 text-sm list-none md:flex-row min-w-max md:space-x-6 lg:space-x-12">
          <li className="text-center">
            <Link
              href="/privacy"
              containerClassName="link-container"
              anchorClassName="custom-underline hover:text-gray-400"
            >
              Privacy Policy
            </Link>
          </li>
          <li className="text-center">
            <Link
              href="/acknowledgements"
              containerClassName="link-container"
              anchorClassName="custom-underline hover:text-gray-400"
            >
              Acknowledgements
            </Link>
          </li>
          <li className="text-center">
            <Link
              href="/contact"
              containerClassName="link-container"
              anchorClassName="custom-underline hover:text-gray-400"
            >
              Contact
            </Link>
          </li>
        </ul>
        <p className="text-xs text-right">
          ©{new Date().getFullYear()} Jonathan Carlson. <br />
          All rights reserved and all that.
        </p>
      </div>
    </footer>
  );
}
